<template>
  <div class="content">
    <chat-modal
      v-if="showChat"
      :chat-obj="chatObj"
      :col-chat="chatObj.isCol"
      @close-chat="closeChat"
      :selectedChat="selectedChat"
    ></chat-modal>
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">Chats</h2>
    </div>
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Chats</h4>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="chat_type.selected"
                @change="fetch"
                placeholder="Type"
              >
                <el-option
                  class="select-primary"
                  v-for="item in chat_type.options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>

              <!--              <base-input>
                              <el-input
                                type="search"
                                class="mb-3 search-input"
                                clearable
                                prefix-icon="el-icon-search"
                                placeholder="Search records"
                                v-model="searchQuery"
                                aria-controls="datatables"
                              >
                              </el-input>
                            </base-input>-->
            </div>
            <el-table :data="tableData">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
                :sortable="`custom`"
              >
              </el-table-column>
              <el-table-column :min-width="135" align="right" label="Actions">
                <div slot-scope="props">
                  <base-button
                    @click.native="chat(props.row)"
                    class="edit btn-link"
                    type="warning"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-align-left-2"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              @input="paginationInput"
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>

</template>
<script>
import {Table, TableColumn, Select, Option} from 'element-ui';
import {BasePagination, Modal} from 'src/components';
import ChatModal from "../Components/ChatModal.vue";
import Fuse from 'fuse.js';
import {api} from "@/api";
import moment from "moment";

export default {
  components: {
    BasePagination,
    ChatModal,
    Modal,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    /*    queriedData() {
          let result = this.tableData;
          if (this.searchedData.length > 0) {
            result = this.searchedData;
          }
          return result.slice(this.from, this.to);
        },*/
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
  },
  data() {
    return {
      showEditModal: false,
      token: '',
      chat_type: {
        selected: 0,
        options: [{name: 'invite', id: 0}, {name: 'request', id: 1}, {name: 'col', id: 2}]
      },
      total: 0,
      selectedSession: {},
      showChat: false,
      selectedChat: [],
      chatObj: {
        colName: '',
        category: '',
        from_id: '',
        inviteID: '',
        selectedChat: '',
        isReq: false,
        to: '',
        from_user: '',
        col_id: '',
        isCol: false,
      },
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['id', 'email', 'createdAt', 'updatedAt'],
      tableColumns: [
        {
          prop: 'invite_request_id',
          label: 'invite id',
          minWidth: 100
        },
        {
          prop: 'col_id',
          label: 'Collaboration id',
          minWidth: 100
        },
        {
          prop: 'col_name',
          label: 'Collaboration name',
          minWidth: 100
        },
        {
          prop: 'pitch',
          label: 'Pitch',
          minWidth: 100
        },
        {
          prop: 'username',
          label: 'Username',
          minWidth: 100
        },
        {
          prop: 'type',
          label: 'type',
          minWidth: 100
        },
        {
          prop: 'message',
          label: 'Last message',
          minWidth: 150
        },

      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  methods: {
    async sortChange(payload) {
      if (payload.order !== null) {
        const _order = {
          descending: 'DESC',
          ascending: 'ASC'
        }
        let response
        response = await api.get(`/cols?filter[order]=${payload.prop} ${_order[payload.order]}&filter[skip]=${this.pagination.perPage * (this.pagination.currentPage - 1)}&filter[limit]=${this.pagination.perPage}`)
        response.data.map(item => {
          this.transformData(item)
        })
        this.tableData = response.data
      } else {
        await this.fetch()
      }

    },
    chat(row) {
      this.chatObj.inviteID = row.invite_request_id
      this.chatObj.col_id = row.col_id
      this.chatObj.from_id = row.from_id
      this.chatObj.colName = row.col_name
      if (row.type === 'invite' || row.type === 'request') {
        this.getChat(row.type, row.invite_request_id)
      } else {
        this.getChat(row.type, row.col_id)
      }
      this.showChat = true
    },
    async getChat(chat_type, id) {
      try {
        const response = await api.get(`/chat-as-admin/${chat_type}/${id}`, {
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })
        this.selectedChat = response.data
      } catch (e) {

      }
    },
    closeChat() {
      this.showChat = false
      this.chatObj.inviteID = ''
      this.selectedChat = ''
      this.chatObj.col_id = ''
      this.chatObj.from_id = ''
      this.chatObj.colName = ''
    },
    transformData(item) {
      item.created_at = this.toNormalDate(item.created_at)
      /*      item.updatedAt = this.toNormalDate(item.updatedAt)
            item.resultTime = this.toNormalDate(item.resultTime)
            if (item.competence) {
              item.competence = item.competence.join(', ')
            }
            if (item.result) {
              item.result = item.result.join(', ')
            }*/

      return item
    },
    toNormalDate(date) {
      if (date !== null) {
        return moment(date).local().format("DD.MM.YYYY, H:mm:ss")
      }
    },
    paginationInput() {
      this.fetch()
    },
    /*    async updateUser() {
          let response

          try {
            const time = this.getCurrentTime
            response = await api.patch(`/selves/${this.selectedUser.id}`, {
              email: this.selectedUser.email,
              updatedAt: time,
              ip: this.selectedUser.ip
            })
            if (response) {
              await this.fetch()
              this.closeEditor()
            }
          } catch (e) {
            console.debug(e)
          }
        },*/
    async fetch() {
      let response
      response = await api.get(`/chat-list_count/${this.chat_type.options[this.chat_type.selected].name}`)
      this.total = parseInt(response.data[0].count)
      await this.fetchData()
    },
    async fetchData() {
      let response
      response = await api.get(`/chat-list/${this.chat_type.options[this.chat_type.selected].name}/${this.pagination.perPage * (this.pagination.currentPage - 1)}/${this.pagination.perPage}`)
      response.data.map(item => {
        this.transformData(item)
      })
      this.tableData = response.data
    },
  },
  mounted() {
    this.token = window.localStorage.getItem('auth_token')
    this.fetch()
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['email'],
      threshold: 0.3
    });
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    }
  }
};
</script>
<style>
.pagination-select  {
  font-size: inherit !important;
}
</style>
