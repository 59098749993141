<template>
  <div class="content">
    <h2 class="text-center">Text Editor</h2>
    <div class="row mt-5">
      <div class="col-12">
        <div class="tmp-row">
          <card>
            <h4 slot="header" class="card-title">Text Editor</h4>
            <div class="flex-card">
              <div class="text-data-editor">
                Name
                <base-input
                  :placeholder="page.name"
                  v-model="page.name"
                >
                </base-input>
                Description
                <base-input
                  :placeholder="page.description"
                  v-model="page.description"
                >
                </base-input>
              </div>
              <div class="editor-wrapper">
                <editor ref="editor" :initialized="onInitialized" :config="config"></editor>
              </div>
              <div class="editor-button-panel">
                <base-button type="danger" fill @click="handleBack">Back</base-button>
                <base-button @click="handleSave">Save</base-button>
              </div>
              <br>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Paragraph from '@editorjs/paragraph'
import LinkTool from '@editorjs/link'
import {api} from "@/api";
import edjsHTML from 'editorjs-html'
import swal from "sweetalert2";


export default {
  name: "EditorPage",
  mounted() {
    /*this.getPages()*/
    if (this.$route.params.hasOwnProperty('id') && this.$route.params.page !== "") {
      this.currentPage = this.$route.params.id
      this.getPage(this.currentPage)
    }
  },
  data() {
    return {
      currentPage: '',
      editorState: null,
      page: null,
      config: {
        tools: {
          linkTool: {
            class: LinkTool
          },
          paragraph: {
            class: Paragraph,
          },
        },
        data: {
          "time": 1591362820044,
          "blocks": [],
          "version": "2.18.0"
        }
      },
    };
  },
  methods: {
    async sendPage(data) {
      let html_str = ''
      const edjsParser = edjsHTML();
      data.blocks.forEach(block => {
        html_str += edjsParser.parseBlock(block)
      })
      try {
        await api.patch(`/mytext/${this.currentPage}`, {
          block: JSON.stringify(data),
          text: html_str.toString(),
          name: this.page.name,
          description: this.page.description
        })
      } catch (e) {

      }
    },
    async getPage(id) {
      const response = await api.get(`/mytext/${id}`)
      this.page = response.data
      /*this.editorState.render(response.data[0].block)*/
    },
    handleBack() {
      this.$router.push('/text-editor')
    },
    onInitialized(editor) {
      this.editorState = editor
      editor.isReady.then(() => {
        editor.render(this.page.block) //{"time": 1609411449357, "blocks": [{"data": {"text": `${this.page.text}`}, "type": "paragraph"}], "version": "2.19.1"}
      })
    },
    handleSave() {
      swal.fire({
        title: 'Are you sure?',
        text: `That changes affect a real site page`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'Yes, save it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.savePage()
          swal.fire({
            title: 'Saved!',
            icon: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    },
    savePage() {
      this.editorState.save().then((outputData) => {
        this.sendPage(outputData)
      }).catch((error) => {
        console.log('Saving failed: ', error)
      });
    }
  }
}
</script>

<style scoped>
.editor-wrapper {
  /*background-color: #bcbcc3;*/
  color: white
}

.tmp-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.editor-button-panel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.half-width {
  width: 90%;
}

.text-data-editor {
  width: 20%;
  margin-bottom: 1rem;
}

.flex-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

</style>
