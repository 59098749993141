<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container chat-container container">
        <div class="default">
          <div class="invite-center">
            <div class="chat-center-mob">
              <div class="chat__info-block">
                <strong> Collaboration:</strong> {{chatObj.colName}}<br>
              </div>
              <div ref="chat_body" class="chat__conversation-body">
                <div  v-for="element in selectedChat">
                  <div class="chat__block">
                    <div :class="[element.from === chatObj.from_id ? 'chat__bubble my-message': 'chat__bubble chat-message']">{{element.message}}
                      <br>
                      <span class="chat__bubble-info" v-if="element.from === chatObj.from_id">Username: {{cropPitch(element.from_user_username)}}</span>
                      <span class="chat__bubble-info" v-if="element.from !== chatObj.from_id && !colChat">Username: {{cropPitch(element.from_user_username)}}</span>
                      <span class="chat__bubble-info" v-if="element.from !== chatObj.from_id && colChat">Username: {{cropPitch(element.from_user_username)}}</span>
                      <br>
                      <span class="chat__bubble-info" v-if="element.from === chatObj.from_id">Pitch: {{cropPitch(element.from_user_pitch)}}</span>
                      <span class="chat__bubble-info" v-if="element.from !== chatObj.from_id && !colChat">Pitch: {{cropPitch(element.from_user_pitch)}}</span>
                      <span class="chat__bubble-info" v-if="element.from !== chatObj.from_id && colChat">Pitch: {{cropPitch(element.from_user_pitch)}}</span>
                      <br>
                      <span class="chat__bubble-info">{{toNormalDate(element.created_at, true)}}</span>
                      <br>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="close-button-wrapper">
            <div class="row row-space">
              <div>
                <base-button
                  @click.native="closeModal"
                >
                  Close
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {api} from "@/api";
import moment from "moment";

export default {
  name: "ChatModal",
  props: {
    chatObj: {},
    selectedChat: '',
    personals: '',
    colChat: false
  },
  data: () => ({
    chatMessage: '',
    connection: null,
    token: ''
  }),
  computed: {
    isEmptyChat() {
      return (this.chatMessage.length < 1)
    },
    buddyUsername() {
      return this.chatObj.from_user === 'me' ? this.chatObj.to : this.chatObj.from_user
    }
  },
  updated() {
    this.scrollToEnd()
    this.markAsRead()
  },
  created() {
    const that = this
    if (window.location.hostname === 'localhost') {
      this.connection = new WebSocket("ws://localhost:8025")
    } else {
      this.connection = new WebSocket(`wss://wisejester.double.systems:8025`)
    }
  },
  mounted() {
    this.token = localStorage.getItem('auth_token')
    this.markAsRead()
  },
  methods: {
    async sendChatColMessage() {
      try {
        await api.post(`/chat-col`, {
          col_id: this.chatObj.col_id,
          message: this.chatMessage
        }, {
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })

        /*        this.selectedChat.push({
                  message: this.chatMessage,
                  from: 'me',
                  to: 'chat'
                })*/
        this.chatMessage = ''
      } catch (e) {

      }
    },
    sendWsMessage(message) {
      this.connection.send(message);
    },
    cropPitch(pitch) {
      if (pitch !== undefined && pitch !== null) {
        if (pitch.length >= 15) {
          return pitch.substr(0, 15) + '...'
        } else {
          return pitch
        }
      } else {
        return 'No pitch'
      }

    },
    closeModal() {
      this.$emit('close-chat')
    },
    toNormalDate(date) {
      if (date !== null) {
        return moment(date).local().format("DD.MM.YYYY, H:mm:ss")
      }
    },
    scrollToEnd() {
      const content = this.$refs.chat_body;
      content.scrollTop = content.scrollHeight;
    },
    async sendChatMessage() {
      try {
        await api.post(`/chat-invite/${this.chatObj.isReq}`, {
          id: this.chatObj.inviteID,
          col_id: this.chatObj.col_id,
          category: this.selectedChat[0].category,
          invite_request_id: this.selectedChat[0].invite_request_id,
          message: this.chatMessage
        }, {
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })

        /*        this.selectedChat.push({
                  message: this.chatMessage,
                  from: 'me',
                  to: 'chat'
                })*/
        this.chatMessage = ''
      } catch (e) {

      }
    },
    markAsRead() {
      if (this.colChat) {
        if (this.selectedChat && this.selectedChat.length) {
          this.selectedChat.forEach(msg => {
            let wsMsg = {
              type: 'col_mark',
              chat_id: '',
              col_id: '',
              user_id: ''
            }
            if (!msg.cm_read) {
              console.debug('cm_read')
              wsMsg.user_id = window.localStorage.getItem('user_id')
              wsMsg.col_id = this.chatObj.col_id
              wsMsg.chat_id = msg.chat_id
              this.sendWsMessage(JSON.stringify(wsMsg))
            }

          })


        }
      } else {
        if (this.selectedChat && this.selectedChat.length) {
          this.selectedChat.forEach(msg => {
            let wsMsg = {
              type: 'mark',
              chat_id: ''
            }
            if (msg.is_read === false && msg.to === 'me') {
              wsMsg.chat_id = msg.chat_id
              this.sendWsMessage(JSON.stringify(wsMsg))
            }

          })


        }
      }


    },
  }
}
</script>

<style lang="scss" scoped>

@mixin tablet {
  @media screen and (min-width: 769px) and (max-width: 1200px - 1px) {
    @content;
  }
}

@mixin phone {
  @media screen and (max-width: 769px - 1px) {
    @content;
  }
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(134, 134, 134, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  @include phone {
    display: block;
    height: 100vh !important;
  }
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  /*  display: flex;
    justify-content: center;
    align-items: center;*/

  @include phone {
    height: 100vh;
  }

}

.chat-container {
  width: fit-content;
}

.modal-container {
  /*    width: 600px;*/
  min-height: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #000000;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;

  @include phone {
    width: 100vw;
    height: 100vh;
  }
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}


.container {


  @include tablet {
    max-width: 90%;
  }

  @include phone {
    max-width: 100% !important;
  }
}

.my-message {
  align-self: flex-end;
}

.chat-message {
  border: 1px solid #bfc2c2 !important;
  background: #bfc2c2 !important;
}

.chat__block {
  display: flex;
  flex-direction: column;
}

.chat__info-block {
  text-align: center;
  margin-bottom: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
}

.chat__bubble-info {
  font-size: 0.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row-space {
  margin: 2rem 0 0 0;
  justify-content: space-between;
  width: 100%;
}
.close-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.chat__conversation-body::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  background-color: #000; /* or add it to the track */
}

/* Add a thumb */
.chat__conversation-body::-webkit-scrollbar-thumb {
  background: #aaa;
  border: 4px solid rgba(0, 0, 0, 0);
}

.chat-area::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  background-color: #000; /* or add it to the track */
}

.chat-area::-webkit-scrollbar-thumb {
  background: #aaa;
  border: 4px solid rgba(0, 0, 0, 0);
}


.chat-center-mob {
  @include phone {
    display: flex;
    flex-direction: column;
  }
}


.pagination-select,
.search-input {
  width: 200px;
}
.swal2-icon-content{
  font-size: inherit !important;
}
.modal-body {
  background-color: #151515;
}
.top-panel {
  width: 30%;
  justify-content: space-between;
}
.chat__conversation-body {
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 600px;
  overflow-x: hidden;
  overflow-y: scroll;

  @include phone {
    width: 250px;
    height: 60vh;
  }
}

.chat__bubble {
  word-wrap: break-word !important;
  font-size: 0.9rem;
  line-height: 1.3;
  padding: 0.5rem 0.5rem;
  border-radius: 0.7rem;
  color: black;
  border: 1px solid #808f95;
  background: #808f95;
  max-width: 90%;
  width: fit-content;
  margin: 0 0.5rem 0.5rem 0.5rem;
}


.invite-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn {
  background-color: lightgray !important;
}

</style>
