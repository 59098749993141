<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">Pool members</h2>
    </div>
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Pool members</h4>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <!--              <base-input>
                              <el-input
                                type="search"
                                class="mb-3 search-input"
                                clearable
                                prefix-icon="el-icon-search"
                                placeholder="Search records"
                                v-model="searchQuery"
                                aria-controls="datatables"
                              >
                              </el-input>
                            </base-input>-->
            </div>
            <el-table @sort-change="sortChange" :data="tableData">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"

                :sortable="`custom`"
              >
              </el-table-column>
              <el-table-column :min-width="135" align="right" label="Actions">
                <div slot-scope="props">
                  <!--                  <base-button
                                      @click.native="handleEdit(props.$index, props.row)"
                                      class="edit btn-link"
                                      type="warning"
                                      size="sm"
                                      icon
                                    >
                                      <i class="tim-icons icon-pencil"></i>
                                    </base-button>-->
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              @input="paginationInput"
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
            <base-button type="danger" fill @click="handleBack">Back</base-button>
          </div>
        </card>
      </div>
    </div>
  </div>

</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination, Modal } from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import {api} from "@/api";
import moment from "moment";

export default {
  components: {
    BasePagination,
    Modal,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    /*    queriedData() {
          let result = this.tableData;
          if (this.searchedData.length > 0) {
            result = this.searchedData;
          }
          return result.slice(this.from, this.to);
        },*/
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
  },
  data() {
    return {
      showEditModal: false,
      selectedUser: {},
      total: 0,
      poolId: '',
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['id', 'email', 'createdAt', 'updatedAt'],
      tableColumns: [
        {
          prop: 'self_id',
          label: 'id',
          minWidth: 100
        },
        {
          prop: 'email',
          label: 'Email',
          minWidth: 250
        },
        {
          prop: 'createdAt',
          label: 'Created at',
          minWidth: 100
        },
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  methods: {
    paginationInput() {
      this.fetch()
    },
    handleBack() {
      this.$router.push(`/pool-list`)
    },
    async sortChange(payload) {
        await this.fetch()
    },
    dateTransform(item) {
      item.createdAt = this.toNormalDate(item.createdAt)
      item.updatedAt = this.toNormalDate(item.updatedAt)
      return item
    },
    toNormalDate(date) {
      if (date !== null) {
        return moment(date).local().format("DD.MM.YYYY, H:mm:ss")
      }
    },
    async fetch() {
      let response
      response = await api.get(`/pool-members-ar/${this.poolId}`)
      response.data.map(item => {
        this.dateTransform(item)
      })
      this.tableData = response.data
    },
  },
  mounted() {
    if (this.$route.params.hasOwnProperty('poolId') && this.$route.params.poolId !== "") {
      this.poolId = this.$route.params.poolId
      this.fetch()
    }

    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['email'],
      threshold: 0.3
    });
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    }
  }
};
</script>
<style>
</style>
