<template>
  <div class="row">
    <div class="col-12">
      <card :title="`Upload warmup images`">
        <label>
          <input type="file" ref="file" @change="saveFiles" multiple/>
        </label>
        <base-button @click="uploadFile" type="primary" fill><i class="tim-icons icon-cloud-upload-94"></i>
        </base-button>
        <p>Successfully uploaded: {{successUpload}}</p>
        <p>Failed: {{failUpload}}</p>
      </card>
    </div>
  </div>
</template>

<script>
import {api} from '@/api'
import {BaseButton} from "@/components"

export default {
  name: "UploadWarmupPage",
  components: {
    BaseButton
  },
  data() {
    return {
      files: [],
      infoMsg: '',
      successUpload: 0,
      failUpload: 0,
      jokesDB: []
    }
  },
  methods: {
    saveFiles() {
      this.files.push(... this.$refs.file.files)
      console.debug(this.$refs.file.files)
      this.removeOld()
    },
    async removeOld() {
      await api.get(`/remove-warmup`)
    },
    async uploadFile() {
      this.successUpload = 0
      this.failUpload = 0
      const that = this
      for (const file of this.files) {
        try {
          const formData = new FormData()
          formData.append("file", file)
          const response = await api.post('/warmup-images', formData)
          await api.get(`/add-name/${response.data.files[0].filename}`)
          that.successUpload ++
        } catch (e) {
          that.failUpload ++
        }
      }
      this.files.splice(0, this.files.length)
    }
  }
}
</script>

<style scoped>

</style>
