const ExcelJS = require('exceljs')


export const parsedJokes = (buffer) => {
  let jokesDB = []

  const workbook = new ExcelJS.Workbook();
  workbook.xlsx.load(buffer)
    .then(function () {
      jokesDB.splice(0, jokesDB.length)
      workbook.eachSheet((worksheet, sheetId) => {
        worksheet.eachRow((row, rowNumber) => {
          jokesDB.push({
            content: row.values[1],
            typeOfHumor: {
              typeA: row.values[2] === 1 ? 1 : 0,
              typeB: row.values[3] === 1 ? 1 : 0,
              typeC: row.values[4] === 1 ? 1 : 0,
              typeD: row.values[5] === 1 ? 1 : 0,
              typeE: row.values[6] === 1 ? 1 : 0
            },
            basedUpon: {
              grasp_1: row.values[7] === 1 ? 1 : 0,
              grasp_2: row.values[8] === 1 ? 1 : 0,
              grasp_3: row.values[9] === 1 ? 1 : 0
            },
            targeting: {
              interpret_1: row.values[10] === 1 ? 1 : 0,
              interpret_2: row.values[11] === 1 ? 1 : 0
            },
            requireActivation: {
              req_1: row.values[12] === 1 ? 1 : 0,
              req_2: row.values[13] === 1 ? 1 : 0,
              req_3: row.values[14] === 1 ? 1 : 0,
              req_4: row.values[15] === 1 ? 1 : 0
            },
            clash: {
              clash_1: row.values[16] === 1 ? 1 : 0,
              clash_2: row.values[17] === 1 ? 1 : 0,
              clash_3: row.values[18] === 1 ? 1 : 0,
              clash_4: row.values[19] === 1 ? 1 : 0,
              clash_5: row.values[20] === 1 ? 1 : 0,
              clash_6: row.values[21] === 1 ? 1 : 0
            }
          })
        })
      })
    });
  return jokesDB
}

