<template>
    <div class="content">
      <h2 class="text-center">Settings</h2>
      <div class="row mt-5">
        <div class="col-12">
          <div class="tmp-row">
            <card>
              <h4 slot="header" class="card-title"></h4>
              <div v-for="(element, key) in systemSettings" v-if="element.name !== 'warmup_images'"  class="title-field invite-center">
                Delay for jokes (ms)
                <base-input
                  v-model="element.value.delay"
                >
                </base-input>
              </div>
              <base-button @click="handleSave">Save</base-button>
            </card>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import {api} from "@/api";
import swal from "sweetalert2";

export default {
  name: "settings",
  data() {
    return {
      systemSettings: []
    };
  },
  mounted() {
    this.getSettings()
  },
  methods: {
    handleSave() {
      swal.fire({
        title: 'Are you sure?',
        text: `That changes affect a real site page`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'Yes, save it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.updateSettings()
          swal.fire({
            title: 'Saved!',
            icon: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    },
    async getSettings() {
      this.systemSettings.splice(0, this.systemSettings.length)
      try {
        const response = await api.get('/system-setting')
        this.systemSettings.push(... response.data)
      } catch (e) {

      }
    },
    async updateSettings() {
      try {
        const response = await api.post('system-setting',
             this.systemSettings
        )
        if (response.status === 204) {
          await this.getSettings()
        }
      } catch (e) {

      }
    }
  }
}
</script>

<style scoped>

</style>
