<template>
  <div class="content">
    <h2 class="text-center">Editor</h2>
    <div class="row mt-5">
      <div class="col-12">
        <div class="tmp-row">
          <card>
            <h4 slot="header" class="card-title">{{this.currentPage}}</h4>
            <div class="title-field invite-center">
              <base-input
                v-model="newTitle"
              >
              </base-input>
            </div>
<!--            <div class="editor-wrapper">
              <editor ref="title-editor" :initialized="onInitializedTitleEditor" :config="titleEditorconfig"></editor>
            </div>-->
            <div class="editor-wrapper">
              <editor ref="editor" :initialized="onInitialized" :config="config"></editor>
            </div>

            <base-button @click="handleSave">Save</base-button>
            <br>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Paragraph from '@editorjs/paragraph'
import ImageTool from '@editorjs/image';
import LinkTool from '@editorjs/link'
import {api} from "@/api";
import edjsHTML from 'editorjs-html'
import swal from "sweetalert2";


export default {
  name: "EditorPage",
  mounted() {
    /*this.getPages()*/
    if (this.$route.params.hasOwnProperty('page') && this.$route.params.page !== "") {
      this.slug = this.$route.params.page
      this.currentPage = this.pagesIds[this.$route.params.page]
      this.getPage(this.currentPage)
    }
  },
  data() {
    return {
      currentPage: '',
      outputData: null,
      newTitle: '',
      outputTitleData: null,
      pagesIds: {
        about: 3,
        home: 2,
        team: 4,
        news: 5,
        useCases: 6
      },
      editorState: null,
      titleEditorState: null,
      page: null,
      config: {
        tools: {
          image: {
            class: ImageTool,
            config: {
              uploader: {
                uploadByFile(file){
                  const formData = new FormData()
                  formData.append("file", file)
                  return api.post(`/upload-editor-img`, formData).then((response) => {
                    return {
                      success: 1,
                      file: {
                        url: response.data.file.url,
                      }
                    }
                  })
                }
              },
              endpoints: {

              }
            }
          },
          linkTool: {
            class: LinkTool
          },
          paragraph: {
            class: Paragraph,
          },
        },
        data: {
          "time": 1591362820044,
          "blocks": [],
          "version": "2.18.0"
        }
      },
      titleEditorconfig: {
        tools: {
          image: {
            class: ImageTool,
            config: {
              uploader: {
                uploadByFile(file){
                  const formData = new FormData()
                  formData.append("file", file)
                  return api.post(`/upload-editor-img`, formData).then((response) => {
                    return {
                      success: 1,
                      file: {
                        url: response.data.file.url,
                      }
                    }
                  })
                }
              },
              endpoints: {

              }
            }
          },
          linkTool: {
            class: LinkTool
          },
          paragraph: {
            class: Paragraph,
          },
        },
        data: {
          "time": 1591362820044,
          "blocks": [],
          "version": "2.18.0"
        }
      },
    };
  },
  methods: {
    async sendPage(data) {
      let html_str = ''
      const edjsParser = edjsHTML();
      data.blocks.forEach(block => {
        html_str += edjsParser.parseBlock(block)
      })
      /*let html = edjsParser.parse(data.blocks);*/
      try {
        await api.patch(`/pages/${this.currentPage}`, {
          block: JSON.stringify(data),
          title_html: this.newTitle,
          html: html_str.toString() /*data.blocks[0].data.text*/
        })
        await api.post('/page-histories', {
          block: JSON.stringify(data),
          title_html: this.newTitle,
          html: html_str.toString(), /*data.blocks[0].data.text,*/
          slug: this.slug
        })
      } catch (e) {

      }
    },
    async getPage(id) {
      const response = await api.get(`/pages/${id}`)
      this.page = response.data
      this.newTitle = response.data.title_html
      /*this.editorState.render(response.data[0].block)*/
    },
    onInitialized(editor) {
      this.editorState = editor
      editor.isReady.then(() => {
        editor.render(this.page.block)
      })
    },
    onInitializedTitleEditor(titleEditor) {
      this.titditorState = titleEditor
      titleEditor.isReady.then(() => {
        titleEditor.render(this.page.title_block)
      })
    },
    handleSave() {
      swal.fire({
        title: 'Are you sure?',
        text: `That changes affect a real site page`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'Yes, save it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.savePage()
          swal.fire({
            title: 'Saved!',
            icon: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    },
    savePage() {
      this.outputData = null
      this.outputTitleData = null
      this.editorState.save().then((outputData) => {
        this.outputData = outputData
        this.sendPage(this.outputData)
      }).catch((error) => {
        console.log('Saving failed: ', error)
      });
    }
  }
}
</script>

<style scoped>
.editor-wrapper {
  /*background-color: #bcbcc3;*/
  color: white
}

.tmp-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.half-width {
  width: 90%;
}


</style>
