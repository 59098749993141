<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">Visits</h2>
    </div>
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Visits</h4>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="host.selected"
                @change="fetch"
                placeholder="Host"
              >
                <el-option
                  class="select-primary"
                  v-for="item in host.options"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="period.selected"
                @change="fetch"
                placeholder="Host"
              >
                <el-option
                  class="select-primary"
                  v-for="item in period.options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>

              <!--              <base-input>
                              <el-input
                                type="search"
                                class="mb-3 search-input"
                                clearable
                                prefix-icon="el-icon-search"
                                placeholder="Search records"
                                v-model="searchQuery"
                                aria-controls="datatables"
                              >
                              </el-input>
                            </base-input>-->
            </div>
            <div>
              <p>Statistics report</p>
              <p>Total visits all time: {{allTotal}}</p>
              <p>Unique finger prints: {{uniqueFP}}</p>
              <p>Unique auth tokens: {{uniqueAuthTokens}}</p>
              <p>Unique IPs: {{uniqueIP}}</p>
            </div>
            <el-table  :data="tableData">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
                :sortable="`custom`"
              >
              </el-table-column>
              <!--              <el-table-column :min-width="135" align="right" label="Actions">
                              <div slot-scope="props">
                                <base-button
                                  @click.native="handleEdit(props.$index, props.row)"
                                  class="edit btn-link"
                                  type="warning"
                                  size="sm"
                                  icon
                                >
                                  <i class="tim-icons icon-pencil"></i>
                                </base-button>
                                <base-button
                                  @click.native="handleDelete(props.$index, props.row)"
                                  class="remove btn-link"
                                  type="danger"
                                  size="sm"
                                  icon
                                >
                                  <i class="tim-icons icon-simple-remove"></i>
                                </base-button>
                              </div>
                            </el-table-column>-->
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              @input="paginationInput"
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>

</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination, Modal } from 'src/components';
import Fuse from 'fuse.js';
import {api} from "@/api";
import moment from "moment";

export default {
  components: {
    BasePagination,
    Modal,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    uniqueFP() {
      return this.getUniqueCount('visitor_id')
    },
    uniqueIP() {
      return this.getUniqueCount('ip4')
    },
    uniqueAuthTokens() {
      return this.getUniqueCount('auth_token')
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
  },
  data() {
    return {
      showEditModal: false,
      total: 0,
      selectedSession: {},
      allTotal: 0,
      period: {
        selected: 0,
        options: [{name: 'day', id: 0},{name: 'week', id: 1}, {name: 'month', id: 2}, {name: 'year', id: 3}]
      },
      host: {
        selected: 'main',
        options: ['main', 'member']
      },
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['id', 'email', 'createdAt', 'updatedAt'],
      tableColumns: [
        {
          prop: 'visitor_id',
          label: 'visitor_id',
          minWidth: 50
        },
        {
          prop: 'host',
          label: 'host',
          minWidth: 50
        },
        {
          prop: 'url',
          label: 'url',
          minWidth: 50
        },
        {
          prop: 'lang',
          label: 'lang',
          minWidth: 50
        },
        {
          prop: 'user_agent',
          label: 'user_agent',
          minWidth: 50
        },
        {
          prop: 'location',
          label: 'location',
          minWidth: 50
        },
        {
          prop: 'ip4',
          label: 'ip4',
          minWidth: 50
        },
        {
          prop: 'rest_headers',
          label: 'Headers',
          minWidth: 200
        },
        {
          prop: 'referer',
          label: 'referer',
          minWidth: 50
        },
        {
          prop: 'auth_token',
          label: 'auth_token',
          minWidth: 50
        },
        {
          prop: 'created_at',
          label: 'Created at',
          minWidth: 50
        }
      ],
      tableData: [],
      fullReport: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  methods: {
    getUniqueCount(field) {
      let uniqueArr = []
      this.fullReport.forEach(row => {
        uniqueArr.push(row[field])
      })
      uniqueArr = [...new Set(uniqueArr)]
      return uniqueArr.length
    },
    async sortChange(payload) {
      if (payload.order !== null) {

      } else {

      }

    },
    transformData(item) {
      item.created_at = this.toNormalDate(item.created_at)
      return item
    },
    toNormalDate(date) {
      if (date !== null) {
        return moment(date).local().format("DD.MM.YYYY, H:mm:ss")
      }
    },
    paginationInput() {
      this.fetch()
    },
    async fetch() {
      let response
      let responseAllCount
      response = await api.get(`/visits/count/${this.host.selected}/${this.period.selected}`)
      responseAllCount = await api.get(`/visits/count/all`)
      this.allTotal = responseAllCount.data[0]['count()']
      this.total = response.data[0]['count()']
      await this.fetchData()
    },
    async getFullReport() {
      let response
      response = await api.get(`/visits/${this.host.selected}/${this.period.selected}/0/${this.total}`)
      this.fullReport = response.data
    },
    async fetchData() {
      let response
      response = await api.get(`/visits/${this.host.selected}/${this.period.selected}/${this.pagination.perPage * (this.pagination.currentPage - 1)}/${this.pagination.perPage}`)
      response.data.map(item => {
        this.transformData(item)
      })
      this.tableData = response.data
      await this.getFullReport()
    },
  },
  mounted() {
    this.fetch()
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['email'],
      threshold: 0.3
    });
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    }
  }
};
</script>
<style>

</style>
