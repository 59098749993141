<template>
  <div class="row">
    <div class="col-12">
      <card :title="`Upload jokes`">
        <label>
          <input type="file" ref="file" @change="saveFile"/>
        </label>
        <base-button @click="preUpload" type="primary" fill><i class="tim-icons icon-cloud-upload-94"></i>
        </base-button>

      </card>
      <p>Successfully uploaded: {{successUpload}}</p>
      <p>Failed: {{failUpload}}</p>
    </div>
  </div>
</template>

<script>
import {api} from '@/api'
import {parsedJokes} from "../../../public/js/ExcelToJson";
import {BaseButton} from "@/components"

export default {
  name: "UploadJokesPage",
  components: {
    BaseButton
  },
  data() {
    return {
      file: '',
      failUpload: 0,
      successUpload: 0,
      jokesDB: []
    }
  },
  methods: {
    saveFile() {
      this.jokesDB.splice(0, this.jokesDB.length)
      this.file = this.$refs.file.files[0];
      const reader = new FileReader()

      reader.readAsArrayBuffer(this.file)
      reader.onload = () => {
        const buffer = reader.result;
        this.jokesDB = parsedJokes(buffer)



      }
      console.debug(this.jokesDB)
    },
    async preUpload() {
      try {
        await api.delete('/remove_jokes')
        this.uploadJokes()
      } catch (e) {

      }
    },
    uploadJokes() {
      this.successUpload = 0
      this.failUpload = 0
      let response
      const that = this
      this.jokesDB.forEach(async function (joke) {
        try {
          response = await api.post('/jokes', {
            content: joke.content,
            typeA: joke.typeOfHumor.typeA,
            typeB: joke.typeOfHumor.typeB,
            typeC: joke.typeOfHumor.typeC,
            typeD: joke.typeOfHumor.typeD,
            typeE: joke.typeOfHumor.typeE,
            grasp_1: joke.basedUpon.grasp_1,
            grasp_2: joke.basedUpon.grasp_2,
            grasp_3: joke.basedUpon.grasp_3,
            interpret_1: joke.targeting.interpret_1,
            interpret_2: joke.targeting.interpret_2,
            req_1: joke.requireActivation.req_1,
            req_2: joke.requireActivation.req_2,
            req_3: joke.requireActivation.req_3,
            req_4: joke.requireActivation.req_4,
            clash_1: joke.clash.clash_1,
            clash_2: joke.clash.clash_2,
            clash_3: joke.clash.clash_3,
            clash_4: joke.clash.clash_4,
            clash_5: joke.clash.clash_5,
            clash_6: joke.clash.clash_6,
          })
          that.successUpload ++
        } catch (e) {
          that.failUpload ++
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
