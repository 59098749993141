<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">Edit text</h2>
    </div>
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Edit text</h4>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <!--              <base-input>
                              <el-input
                                type="search"
                                class="mb-3 search-input"
                                clearable
                                prefix-icon="el-icon-search"
                                placeholder="Search records"
                                v-model="searchQuery"
                                aria-controls="datatables"
                              >
                              </el-input>
                            </base-input>-->
            </div>
            <el-table @sort-change="sortChange" :data="tableData">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"

                :sortable="`custom`"
              >
              </el-table-column>
              <el-table-column :min-width="135" align="right" label="Actions">
                <div slot-scope="props">
                  <base-button
                    @click.native="handleEdit(props.$index, props.row)"
                    class="edit btn-link"
                    type="warning"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              @input="paginationInput"
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>

</template>
<script>
import {Option, Select, Table, TableColumn} from 'element-ui';
import {BasePagination, Modal} from 'src/components';
import Fuse from 'fuse.js';
import {api} from "@/api";
import moment from "moment";
import LinkTool from "@editorjs/link";
import Paragraph from "@editorjs/paragraph";

export default {
  components: {
    BasePagination,
    Modal,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    /*    queriedData() {
          let result = this.tableData;
          if (this.searchedData.length > 0) {
            result = this.searchedData;
          }
          return result.slice(this.from, this.to);
        },*/
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
  },
  data() {
    return {
      showEditModal: false,
      selectedUser: {},
      total: 0,
      editorState: null,
      page: null,
      config: {
        tools: {
          linkTool: {
            class: LinkTool
          },
          paragraph: {
            class: Paragraph,
          },
        },
        data: {
          "time": 1591362820044,
          "blocks": [],
          "version": "2.18.0"
        }
      },
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['id', 'email', 'createdAt', 'updatedAt'],
      tableColumns: [
        {
          prop: 'slug',
          label: 'slug',
          minWidth: 100
        },
        {
          prop: 'name',
          label: 'name',
          minWidth: 100
        },
        {
          prop: 'description',
          label: 'description',
          minWidth: 250
        },
        {
          prop: 'updatedAt',
          label: 'Updated At',
          minWidth: 100
        }
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  methods: {
    paginationInput() {
      this.fetch()
    },
    async sortChange(payload) {
      if (payload.order !== null) {
        const _order = {
          descending: 'DESC',
          ascending: 'ASC'
        }
        let response
        response = await api.get(`/mytext?filter[order]=${payload.prop} ${_order[payload.order]}&filter[skip]=${this.pagination.perPage * (this.pagination.currentPage - 1)}&filter[limit]=${this.pagination.perPage}`)
        response.data.map(item => {
          this.dateTransform(item)
        })
        this.tableData = response.data
      } else {
        await this.fetch()
      }

    },
    dateTransform(item) {
      item.createdAt = this.toNormalDate(item.createdAt)
      item.updatedAt = this.toNormalDate(item.updatedAt)
      return item
    },
    toNormalDate(date) {
      if (date !== null) {
        return moment(date).local().format("DD.MM.YYYY, H:mm:ss")
      }
    },
    async deleteUser(id) {
      let response

      try {
        response = await api.delete(`/mytext/${id}`)
        if (response) {
          await this.fetch()
        }
      } catch (e) {
        console.debug(e)
      }
    },
    closeEditor() {
      this.showEditModal = false
    },
    async updateUser() {
      let response

      try {
        response = await api.patch(`/mytext/${this.selectedUser.id}`, {})
        if (response) {
          await this.fetch()
          this.closeEditor()
        }
      } catch (e) {
        console.debug(e)
      }
    },
    async fetch() {
      let response
      response = await api.get('/mytext/count')
      this.total = response.data.count
      await this.fetchData()
    },
    async fetchData() {
      let response
      response = await api.get(`/mytext?filter[skip]=${this.pagination.perPage * (this.pagination.currentPage - 1)}&filter[limit]=${this.pagination.perPage}&filter[order]=createdAt DESC`)
      response.data.map(item => {
        this.dateTransform(item)
      })
      this.tableData = response.data
    },
    handleEdit(index, row) {
      /*      swal.fire({
              title: `You want to edit ${row.name}`,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-info btn-fill'
              }
            });*/
      this.$router.push(`/text-editor-page/${row.id}`)
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    }
  },
  mounted() {
    this.fetch()
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['email'],
      threshold: 0.3
    });
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    }
  }
};
</script>
<style>
</style>
