<template>
  <div class="content">
    <div class="col-md-8 ml-auto mr-auto">
      <h2 class="text-center">Sessions</h2>
    </div>
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Sessions</h4>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <!--              <base-input>
                              <el-input
                                type="search"
                                class="mb-3 search-input"
                                clearable
                                prefix-icon="el-icon-search"
                                placeholder="Search records"
                                v-model="searchQuery"
                                aria-controls="datatables"
                              >
                              </el-input>
                            </base-input>-->
            </div>
            <el-table @sort-change="sortChange" :data="queriedData">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
                :sortable="`custom`"
              >
              </el-table-column>
              <el-table-column :min-width="135" align="right" label="Actions">
                <div slot-scope="props">
                  <base-button
                    @click.native="handleEdit(props.$index, props.row)"
                    class="edit btn-link"
                    type="warning"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                  <base-button
                    @click.native="handleDelete(props.$index, props.row)"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>

</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination, Modal } from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import {api} from "@/api";
import moment from "moment";

export default {
  components: {
    BasePagination,
    Modal,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      showEditModal: false,
      selectedSession: {},
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['id', 'email', 'createdAt', 'updatedAt'],
      tableColumns: [
        {
          prop: 'id',
          label: 'id',
          minWidth: 100
        },
        {
          prop: 'selfId',
          label: 'UserID',
          minWidth: 100
        },
        {
          prop: 'email',
          label: 'email',
          minWidth: 150
        },
        {
          prop: 'createdAt',
          label: 'Created at',
          minWidth: 100
        },
        {
          prop: 'updatedAt',
          label: 'Updated At',
          minWidth: 100
        },
        {
          prop: 'resultTime',
          label: 'Result Time',
          minWidth: 100
        },
        {
          prop: 'result',
          label: 'result',
          minWidth: 150
        },
        {
          prop: 'competence',
          label: 'competence',
          minWidth: 150
        },
        {
          prop: 'status',
          label: 'status',
          minWidth: 100
        }
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  methods: {
    async sortChange(payload) {
      if (payload.order !== null) {
        const _order = {
          descending: 'DESC',
          ascending: 'ASC'
        }
        let response
        response = await api.get(`/sessions?filter[order]=${payload.prop} ${_order[payload.order]}`)
        response.data.map(item => {
          this.transformData(item)
        })
        this.tableData = response.data
      } else {
        await this.fetch()
      }

    },
    transformData(item) {
      item.createdAt = this.toNormalDate(item.createdAt)
      item.updatedAt = this.toNormalDate(item.updatedAt)
      item.resultTime = this.toNormalDate(item.resultTime)
      if (item.competence) {
        item.competence = item.competence.join(', ')
      }
      if (item.result) {
        item.result = item.result.join(', ')
      }

      return item
    },
    toNormalDate(date) {
      if (date !== null) {
        return moment(date).local().format("DD.MM.YYYY, H:mm:ss")
      }
    },
    closeDeleteModal() {
      this.showDeleteModal = false
    },
    async deleteSession() {
      let response

      try {
        response = await api.delete(`/sessions/${this.selectedSession.id}`)
        if (response) {
          await this.fetch()
        }
      } catch (e) {
        console.debug(e)
      }
    },
    closeEditor() {
      this.showEditModal = false
    },
/*    async updateUser() {
      let response

      try {
        const time = this.getCurrentTime
        response = await api.patch(`/selves/${this.selectedUser.id}`, {
          email: this.selectedUser.email,
          updatedAt: time,
          ip: this.selectedUser.ip
        })
        if (response) {
          await this.fetch()
          this.closeEditor()
        }
      } catch (e) {
        console.debug(e)
      }
    },*/
    async fetch() {
      let response
      response = await api.get('/sessions?filter[order]=createdAt DESC')
      response.data.map(item => {
        this.transformData(item)
      })
      this.tableData = response.data
    },
/*    handleLike(index, row) {
      swal.fire({
        title: `You liked ${row.name}`,
        buttonsStyling: false,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-success btn-fill'
        }
      });
    },*/
    handleEdit(index, row) {
      /*      swal.fire({
              title: `You want to edit ${row.name}`,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-info btn-fill'
              }
            });*/
      this.showEditModal = true
      this.selectedUser = Object.assign({}, row)
    },
    handleDelete(index, row) {
      swal.fire({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(row);
          this.deleteSession(row.id)
          swal.fire({
            title: 'Deleted!',
            icon: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        }
      });
    },
    deleteRow(row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    }
  },
  mounted() {
    this.fetch()
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['email'],
      threshold: 0.3
    });
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    }
  }
};
</script>
<style>
</style>
